* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

body {
    background-color: #F0F0F7 /*#f4f6f8*/;
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

canvas {
    position: relative;
    z-index: 1
}
